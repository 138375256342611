import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import CategoryCard from '../../components/molecules/categoryCard'
import Layout from '../../components/organisms/layout'
import { CategoryKind } from '../../types/CategoryKind'
import useRouteParam from '../../hooks/useRouteParam'

type IProps = PageProps<GatsbyTypes.SettingPageQuery>

/**
 * 各種設定ページ
 * @constructor
 */
const SettingPage = ({ data, location }: IProps) => {
  // GraphQLで取得したデータを保持
  const items = data.awsGraphQL.listHelpCategories?.items || []

  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    { title: '各種設定' },
  ]

  return (
    <Layout
      location={location}
      paths={paths}
      categoryKind={CategoryKind.SETTING}
    >
      <SEO title="各種設定" />
      <CommonH2 label="各種設定" />
      <p className="txt-center mt-020 mb-020">
        従業員の登録や締め処理など、主にシステム管理者が利用する機能についてご案内します。
      </p>
      {[...items]
        .sort((a, b) => (a?.sortNo || 0) - (b?.sortNo || 0))
        .filter((item) => !item?._deleted)
        .map((item) => {
          const helpCategoryId = item?.helpCategoryId || ''
          const slug = item?.slug || ''
          const title = item?.title || ''
          return (
            <CategoryCard
              key={`category-card-${helpCategoryId}`}
              helpCategoryId={helpCategoryId}
              categoryTitle={title}
              slug={slug}
              limit={10}
              visibleAllLink
            />
          )
        })}
    </Layout>
  )
}

export default SettingPage

export const pageQuery = graphql`
  query SettingPage {
    awsGraphQL {
      listHelpCategories(filter: { categoryKind: { eq: 4 } }) {
        items {
          helpCategoryId
          sortNo
          title
          slug
          categoryKind
          _deleted
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
